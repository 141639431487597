$blueF:     #132c42
$blueE:     #1e2e4a
$blueD:     #2d4364
$blueC:     #407395
$blueB:     #7ca6c3
$blueA:     #c9dee8

$redA:      #ee5c4d
$yellowA:   #ffdf73
$greyA:     #edf0f2
$greyB:     #bcbec0
$greyC:     #414042
$greyD:     #2d2a2b

$formalA:   $blueD
$formalB:   #79281d
$formalC:   $blueA
$formalD:   $greyB
$formalE:   $redA

$relationalA: #b3ded3
$relationalB: $blueD
$relationalC: $redA
$relationalD: white
$relationalE: #d6eef0

// Below are colors that may exist in my.smbc.edu.au and/or smbc.edu.au.

$lightestblue:  $blueA
$lightblue:     $blueB
$darkestblue:   $blueF
$smbcred:       $redA
$lightgrey:     #d1d3d4
$grey:          #808080
$darkgrey:      #333333
$green:         #00a651
$offwhite:      #f4f4f4
$black:         #000000

// END COLOURS

