/******************
    User custom CSS
    ---------------
    In this file you can add your own custom CSS
    It will be loaded last, so you can override any other property.
    Also, it will never be updated. So if you inheritate a core template and just add here some CSS, you will still benefit of all the updates

@import "smbc-variables"
@import "BuloRounded"

body
    font-family: "Open Sans", sans-serif
    font-size: 16px
    .large-heading
       font-family: "Open Sans", sans-serif
    &.brand-logo
        .top-container
            margin-top: 0.5em !important
            .top-content
                padding-top: 0.5em
    .large-heading
        font-family: "BuloRounded-Medium"
        font-size: 3.5em
    .group-container
        .group-title
            font-family: "BuloRounded-Medium"
            color: $blueB
            font-size: 2.5em

    i.asterisk
        color: $redA

@media (max-width: 400px)
    .logo-container img
        max-height: 80px
